<template>
	<div style="">
		<a-row>
			<a-col :span="8">
				<div class="personalInformation">
					<a-page-header :ghost="false" title="个人信息">
					  <template slot="extra">
					    <a-icon type="more" />
					  </template>
					</a-page-header>
					<div class="pi_div">
						<div class="pi_di_headDiv">
							<el-image class="pi_di_hd_img" :src="require('@/assets/img/head.png')" :fit="'cover'"></el-image>
							<div class="pi_di_hd_name">您好,{{userInfo.contacttel}}</div>
						</div>
						<div class="pi_di_conatentDiv">
							<div class="pi_di_cd_name" style="">客户编号：{{userInfo.id}}</div>
							<div style="padding-top: 10px;">手机号码：{{userInfo.contacttel}}</div>
							<div style="padding-top: 10px;color: red;">积分：{{userInfo.userscore}}</div>
						</div>
					</div>
				</div>
			</a-col>
			
			<a-col :span="8">
				<div class="consultant">
					<a-page-header :ghost="false" title="专属业务顾问">
					  <template slot="extra">
					    <a-icon type="more" />
					  </template>
					</a-page-header>
					<div class="cl_div">
						<div class="cl_di_headDiv">
							<el-image class="cl_di_hd_img" :src="require('@/assets/img/s-head@icon.png')" :fit="'cover'"></el-image>
							<div class="cl_di_hd_name">专属业务顾问:阁先强</div>
						</div>
						<div class="cl_di_conatentDiv">
							<div class="cl_di_cd_name" style="">手机/微信：18028762289</div>
							<div style="padding-top: 10px;">QQ：154615158</div>
							<div style="padding-top: 10px;">售后电话：18028762289</div>
						</div>
					</div>
				</div>
			</a-col>
		</a-row>
	</div>
</template>

<script>
	import Cookies from "js-cookie";
	export default {
		data(){
			return {
				userInfo:null,//用户信息
			}
		},
		
		created() {
			this.userInfo = Cookies.get("userInfo2")
			if(this.userInfo){
				this.userInfo = JSON.parse(this.userInfo)
			}
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	.personalInformation{
		background: #fff;
		margin-right: 20px;
		
		.pi_div{
			padding: 20px;
			
			.pi_di_headDiv{
				text-align: center;
				
				.pi_di_hd_img{
					width: 4vw;
					height: 4vw;
					border-radius: 50%;
				}
				
				.pi_di_hd_name{
					text-align: center;
					padding-top: 20px;
				}
			}
			
			.pi_di_conatentDiv{
				padding-top: 20px;
				font-size: 16px;
				
				.pi_di_cd_name{
					
				}
			}
		}
	}
	
	.consultant{
		background: #fff;
		margin-right: 20px;
		
		.cl_div{
			padding: 20px;
			
			.cl_di_headDiv{
				text-align: center;
				
				.cl_di_hd_img{
					width: 4vw;
					height: 4vw;
					border-radius: 50%;
				}
				
				.cl_di_hd_name{
					text-align: center;
					padding-top: 20px;
				}
			}
			
			
			.cl_di_conatentDiv{
				padding-top: 20px;
				font-size: 16px;
				
				.cl_di_cd_name{
					
				}
			}
		}
	}
</style>
